// index.tsx
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'leaflet/dist/leaflet.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-image-crop/dist/ReactCrop.css'
import App from './App'
import { AuthProvider } from './AuthContext'
import Providers from './providers'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <Providers>
          <App />
        </Providers>
      </AuthProvider>
    </LocalizationProvider>
  </React.StrictMode>,
)

reportWebVitals()
