import { UseZodRequestPasswordResetSchema } from '~/components/FormPasswordRequestReset/useZodPasswordRequestReset'
import { UseZodPasswordResetSchema } from '~/components/FormPasswordReset/useZodPasswordReset'
import { apiSlice } from './api-slice'

type Response = {
  success: boolean
  message: string
  data: Record<string, any>
}

export const accountApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['AccountDetail'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      requestPasswordReset: builder.mutation<Response, UseZodRequestPasswordResetSchema>({
        query: (data) => ({
          url: '/account/request-reset-password/',
          method: 'POST',
          body: { ...data },
        }),
      }),
      resetPassword: builder.mutation<
        Response,
        UseZodPasswordResetSchema & { uidb64: string; token: string }
      >({
        query: (data) => ({
          url: '/account/reset-password/',
          method: 'POST',
          body: { ...data },
        }),
      }),
    }),
  })

export const { useRequestPasswordResetMutation, useResetPasswordMutation } = accountApiSlice
