import RequestReset from '~/pages/password/RequestReset'
import Reset from '~/pages/password/Reset'

export const passwordRoutes = [
  {
    path: 'password',
    children: [
      { index: true, element: <RequestReset /> },
      { path: 'reset', element: <Reset /> },
      { path: 'reset/:uidb64/:token', element: <Reset /> },
    ],
  },
]
