import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import FormPasswordReset from '~/components/FormPasswordReset'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'

const PasswordReset = () => {
  const { i18n } = useTranslation()

  const tPage = useMemo(() => {
    const data = i18n.store.data
    const lang = i18n.language
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = data[lang].translation?.page?.password?.requestReset

    return page
  }, [i18n])

  const { title, content } = tPage || {}

  const { uidb64, token } = useParams() // Get parameters from URL
  const navigate = useNavigate()

  // State to store uuid and token
  const [credentials, setCredentials] = useState({ uidb64: '', token: '' })

  useEffect(() => {
    // If uuid and token are present, store them in state
    if (uidb64 && token) {
      setCredentials({ uidb64, token })

      // Navigate to clean URL without parameters
      navigate('/password/reset', { replace: true })
    }
  }, [uidb64, token, navigate])

  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          <Typography variant="h3">{title}</Typography>

          <Typography variant="body1" mt={3}>
            {content}
          </Typography>
        </Col>
      </Row>

      {credentials && (
        <Row>
          <Col xs={12} mt={3}>
            <FormPasswordReset {...credentials} />
          </Col>
        </Row>
      )}
    </Section>
  )
}

export default PasswordReset
