import { onAuthStateChanged, User } from 'firebase/auth'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { auth } from './firebaseConfig' // Import the auth instance from firebaseConfig.ts
import LoginPage from './pages/FirebaseLogin'

interface AuthContextType {
  user: User | null
  loading: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface AuthProviderProps {
  children: ReactNode
}

const allowedEmails = ['admin@givenhealth.eu', 'fabiopcascao@gmail.com']

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          if (allowedEmails.includes(user.email ?? '')) {
            setUser(user)
            setAuthorized(true)
          } else {
            setUser(null) // Not an allowed email
          }
          setLoading(false)
        } else {
          setUser(null)
          setLoading(false)
        }
      })
      return () => unsubscribe()
    } else {
      // Mock user or leave as null for production, depending on how you want to handle it.
      setUser({} as User)
      setLoading(false)
    }
  }, [])

  if (process.env.NODE_ENV !== 'development') return <>{children}</>

  if (loading) {
    return <div></div>
  }

  if (user && authorized) {
    return <>{children}</>
  }

  return <LoginPage />
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
